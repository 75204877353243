import { useRef } from 'react'

import classes from './Preview.module.css'


export default function PreviewConsulting() {

  const previewRef = useRef()

  return (
    <div className={classes.preview} ref={previewRef} style={{ 
      backgroundImage: `url(${process.env.PUBLIC_URL + '/img/bg-consulting.jpg'})` 
    }}>
      <h3>SPOJUJEME TALENTY S PŘÍLEŽITOSTMI:<br />VAŠE CESTA K ÚSPĚCHU ZAČÍNÁ S NÁMI</h3>
      <button>KONTAKTOVAT</button>
    </div>
  )
}
