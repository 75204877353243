import Form from "../components/Form";
import Partners from "../components/Partners";
import PreviewLogistic from "../components/Preview/PreviewLogistic";
import OfferLogistic from "../components/Offer/OfferLogistic";


export default function MsLogistic() {
    return (
        <>
            <PreviewLogistic />
            <OfferLogistic />
            <Partners />
            <Form />
        </>
    )
}
