import { useRef } from 'react';

import classes from './OfferIcons.module.css'

export default function OfferProduction() {

    const shortOfferRef = useRef()

    return (
        <div id='shortOffer' className={classes.short} ref={shortOfferRef}>
            <div>
                <img src='./img/icons/Production/graf.svg' alt='boom' />
                <h2>Provádění důkladné analýzy výrobních procesů společnosti za účelem identifikace možností optimalizace a zvýšení efektivity</h2>
            </div>
            <div>
                <img src='./img/icons/Production/letterChecked.svg' alt='boom' />
                <h2>Vypracování optimálního plánu práce a rozdělení povinností mezi zahraničními a místními pracovníky s cílem zajistit efektivní výrobní činnost</h2>
            </div>
            <div>
                <img src='./img/icons/Production/persons.svg' alt='boom' />
                <h2>Poskytování služeb v oblasti řízení personálu, včetně plánování pracovní doby, kontroly pracovní doby, motivace zaměstnanců a rozvoje kariéry</h2>
            </div>
            <div>
                <img src='./img/icons/Production/structure.svg' alt='boom' />
                <h2>Vypracování optimální organizace skladové práce s ohledem na požadavky na skladování zboží a zajištění rychlé dostupnosti</h2>
            </div>
            <div>
                <img src='./img/icons/Production/talkingPerson.svg' alt='boom' />
                <h2>Poskytování poradenské podpory v oblasti výrobního procesu a skladové činnosti, řešení případných problémů a hledání optimálních řešení pro zvýšení efektivity práce</h2>
            </div>
            <div>
                <img src='./img/icons/Production/letter.svg' alt='boom' />
                <h2>Vypracování optimálního plánu práce a rozdělení povinností mezi zahraničními a místními pracovníky s cílem zajistit efektivní výrobní činnost</h2>
            </div>
        </div>
    );
}