import { useRef } from 'react'

import classes from './Preview.module.css'


export default function PreviewTrade() {

    const previewRef = useRef()

    return (
        <div className={classes.preview} ref={previewRef} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/img/bg-trade.jpg'})`
        }}>
            <h3>INOVUJEME NÁBOR:<br />NAJDĚTE SPRÁVNÉ LIDI PRO SPRÁVNÁ MÍSTA S NAŠÍM TÝMEM</h3>
            <button>KONTAKTOVAT</button>
        </div>
    )
}
