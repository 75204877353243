import Form from "../components/Form";
import Partners from "../components/Partners";
import PreviewConsulting from "../components/Preview/PreviewConsulting";
import OfferConsulting from "../components/Offer/OfferConsulting";


export default function MsConsulting() {
    return (
        <>
            <PreviewConsulting />
            <OfferConsulting />
            <Partners />
            <Form />
        </>
    )
}
