import { Link } from 'react-router-dom'
import classes from './Home.module.css'

export default function Home() {
    return (
        <div className={classes.home} style={{ 
            backgroundImage: `url(${process.env.PUBLIC_URL + '/img/bg-home.jpg'})` 
          }}>
            <Link to='/production'>
                <div className={classes.logo}>

                    <img
                        src='./img/logo.svg'
                        alt='ms-tek'
                    />
                    <h4>PRODUCTION</h4>
                </div>
            </Link>
            <Link to='/trade'>
                <div className={classes.logo}>

                    <img
                        src='./img/logo.svg'
                        alt='ms-tek'
                    />
                    <h4>TRADE</h4>
                </div>
            </Link>
            <Link to='/logistic'>
                <div className={classes.logo}>

                    <img
                        src='./img/logo.svg'
                        alt='ms-tek'
                    />
                    <h4>LOGISTIC</h4>
                </div>
            </Link>
            <Link to='/consulting'>
                <div className={classes.logo}>

                    <img
                        src='./img/logo.svg'
                        alt='ms-tek'
                    />
                    <h4>CONSULTING</h4>

                </div>
            </Link>
        </div>
    )
}
