import { useRef } from 'react'

import classes from './Preview.module.css'


export default function PreviewLogistic() {

  const previewRef = useRef()

  return (
    <div className={classes.preview} ref={previewRef} style={{ 
      backgroundImage: `url(${process.env.PUBLIC_URL + '/img/bg-logistic.jpg'})` 
    }}>
      <h3>OBJEVUJEME TALENTY, KTERÉ MĚNÍ HRY:<br />SPOLEČNĚ BUDUJEME BUDOUCNOST VAŠEHO TÝMU</h3>
      <button>KONTAKTOVAT</button>
    </div>
  )
}
