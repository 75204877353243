import { useRef } from 'react';

import classes from './OfferImg.module.css'

export default function OfferTrade() {

    const shortOfferRef = useRef()

    return (
        <div id='shortOffer' className={classes.short} ref={shortOfferRef}>
            <div>
                <img src='./img/Trade/bgOffer1.jpg' alt='boom' />
                <div>
                    <ul>
                        <li>Určení konkrétních potřeb klienta ohledně pracovníků. To zahrnuje analýzu pracovních pozic, požadavky na kandidáty a další specifické požadavky</li>
                        <li>Profesionální výběr kandidátů, kteří odpovídají potřebám klienta. To zahrnuje inzerování pracovních pozic, analýzu životopisů, pohovory a ověřování referencí</li>
                        <li>Provádění vzdělávacích programů a školení pro pracovníky s cílem zvýšení jejich efektivity a odpovídání požadavkům klienta</li>
                    </ul>
                </div>
            </div>
            
            <div>
                <div>
                    <ul>
                        <li>Poskytování služeb v oblasti řízení personálu, včetně plánování grafikonů, sledování pracovní doby, administrace plateb a dalších aspektů řízení pracovních procesů</li>
                        <li>Zajištění systematického monitorování pracovní činnosti pracovníků a poskytování zpráv klientovi o výsledcích jejich práce</li>
                        <li>Poskytování trvalé podpory klientům při řešení jakýchkoli otázek nebo problémů souvisejících s personálem, a také poradenství v oblasti optimalizace pracovních procesů</li>
                    </ul>
                </div>
                <img src='./img/Trade/bgOffer2.jpg' alt='boom' />
            </div>
        </div>
    );
}