import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import { Suspense } from "react";
import MsProduction from "./pages/MsProduction";
import Home from "./pages/Home";
import MsTrade from "./pages/MsTrade";
import MsLogistic from "./pages/MsLogistic";
import MsConsulting from "./pages/MsConsulting";


function App() {
  return (
    <Suspense fallback={<p>loading...</p>}>

      <Routes>
        <Route path='/' element={<Layout hide={true}><Home /></Layout >} />
        <Route path='/production' element={<Layout><MsProduction /></Layout >} />
        <Route path='/trade' element={<Layout><MsTrade /></Layout >} />
        <Route path='/logistic' element={<Layout><MsLogistic /></Layout >} />
        <Route path='/consulting' element={<Layout><MsConsulting /></Layout >} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>

  );
}

export default App;
