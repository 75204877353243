import Form from "../components/Form";
import Partners from "../components/Partners";
import PreviewProduction from "../components/Preview/PreviewProduction";
import OfferProduction from "../components/Offer/OfferProduction";


export default function MsProduction() {
    return (
        <>
            <PreviewProduction />
            <OfferProduction />
            <Partners />
            <Form />
        </>
    )
}
