import { useRef } from 'react';

import classes from './OfferImg.module.css'

export default function OfferConsulting() {

    const shortOfferRef = useRef()

    return (
        <div id='shortOffer' className={classes.short} ref={shortOfferRef}>
            <div>
                <img src='./img/Consulting/bgOffer1.jpg' alt='boom' />
                <div>
                    <ul>
                        <li>Provádění detailní analýzy potřeb klienta ohledně získání zahraniční pracovní síly, včetně požadavků na kvalifikaci, pracovní zkušenosti, jazykových schopností a dalších kritérií</li>
                        <li>Vytvoření individuální strategie náboru personálu v zahraničí s ohledem na specifika pracovního trhu, právní aspekty a požadavky země, do které bude personál naverbován</li>
                        <li>Provedení vyhledávání a výběru kvalifikovaných zahraničních kandidátů, včetně inzerování volných pozic, posouzení životopisů, organizaci pohovorů a ověření referencí</li>
                    </ul>
                </div>
            </div>

            <div>
                <div>
                    <ul>
                        <li>Poskytování právních konzultací v otázkách dokumentace, získání víz a pracovních povolení pro zahraniční pracovníky</li>
                        <li>Zajištění informační a praktické podpory pro zahraniční pracovníky během procesu adaptace na novou zemi, včetně přípravy na přesun a kulturního tréninku</li>
                        <li>Poskytování podpory a konzultací pro zahraniční pracovníky a jejich zaměstnavatele po přesunu, s cílem řešení otázek adaptace a zajištění úspěšné integrace na novém pracovním místě</li>
                    </ul>
                </div>
                <img src='./img/Consulting/bgOffer2.jpg' alt='boom' />
            </div>
        </div>
        
    );
}