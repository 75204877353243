import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import classes from './Partners.module.css'

export default function Partners() {
    const partnersRef = useRef()

    const location = useLocation();
    const path = location.pathname;

    const color = () => {
        return path === '/production' ? '#19357D' : path === '/trade' ? '#617e8e' : path === '/logistic' ? '#66584d' : '#a2826c'
    }

    return (
        <div id='partners' className={classes.partners} ref={partnersRef} style={{
            backgroundColor: color(),
        }}>
            <h1>Naše partneři</h1>
            <div>
                <a href="https://imvagency.cz" target="_blank" rel="noreferrer"><img src="./img/partners/imv.svg" alt="partner" /></a>
                <a href="https://bestwork4you.cz" target="_blank" rel="noreferrer"><img src="./img/logo.svg" alt="partner" /></a>
                <a href="https://bestwork4you.cz" target="_blank" rel="noreferrer"><img src="./img/logo.svg" alt="partner" /></a>
                <a href="https://bestwork4you.cz" target="_blank" rel="noreferrer"><img src="./img/logo.svg" alt="partner" /></a>
            </div>
        </div>

    )
}
