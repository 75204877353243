import Form from "../components/Form";
import Partners from "../components/Partners";
import PreviewTrade from "../components/Preview/PreviewTrade";
import OfferTrade from "../components/Offer/OfferTrade";


export default function MsTrade() {
    return (
        <>
            <PreviewTrade />
            <OfferTrade />
            <Partners />
            <Form />
        </>
    )
}