import { useRef } from 'react'

import classes from './Preview.module.css'


export default function PreviewProduction() {

  const previewRef = useRef()

  return (
    <div className={classes.preview} ref={previewRef} style={{ 
      backgroundImage: `url(${process.env.PUBLIC_URL + '/img/bg-production.jpg'})` 
    }}>
      <h3>VAŠE VIZE, NAŠE MISE:<br />POSKYTUJEME KLÍČOVÉ TALENTY PRO VAŠI EXPANZI</h3>
      <button>KONTAKTOVAT</button>
    </div>
  )
}
