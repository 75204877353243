import { useRef, useState } from 'react';
import classes from './Form.module.css'
import { useLocation } from 'react-router-dom'

import { useHttp } from '../hooks/useHttp';

export default function Form() {
    const [form, setForm] = useState({ name: '', mail: '', text: '' })
    const { req, loading, error, message } = useHttp();

    const formRef = useRef()

    const location = useLocation();
    const path = location.pathname;

    const color = () => {
        return path === '/production' ? '#19357D' : path === '/trade' ? '#617e8e' : path === '/logistic' ? '#66584d' : '#a2826c'
    }

    const [isHovering, setIsHovering] = useState(false);

    const hover = () => {
        setIsHovering(!isHovering);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const sendHandler = async (event) => {
        event.preventDefault();
        try {
            await req('/api/form/send', 'POST', { ...form })
            setForm({ name: '', phone: '', mail: '', text: '' });

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>

            <form id='form' className={classes.form} onSubmit={sendHandler} ref={formRef}>

                <h1>Konatktujte nas</h1>
                <h4>Vyplňte prosím níže uvedený formulář a my se vám obratem ozveme.</h4>
                {loading && <p>loading...</p>}
                {message && <p className={classes.msg}>{message}</p>}
                {error && <p className={classes.err}>{error}</p>}

                <input
                    placeholder='Jmeno'
                    type='text'
                    name='name'
                    value={form.name}
                    onChange={handleInputChange}
                    required
                />
                <input
                    placeholder='E-mail'
                    type='text'
                    name='mail'
                    value={form.mail}
                    onChange={handleInputChange}
                    required
                />
                <textarea
                    placeholder='Zpráva'
                    name='text'
                    value={form.text}
                    onChange={handleInputChange}
                />

                <div>
                    <input id="check2" type="checkbox" name="check" required />
                    <label htmlFor="check2">Souhlasím se zpracováním osobních údajů za účelem kontaktu.</label>
                </div>
                <button type='submit'
                    className={classes.hover}
                    onMouseEnter={hover}
                    onMouseLeave={hover}
                style={{
                    backgroundColor: isHovering ? 'rgba(47, 59, 50, 0.9)' : color() ,
                }}
                >
                    Poslat zpravu</button>
            </form>
        </>
    );
};
