import { useRef } from 'react';

import classes from './OfferIcons.module.css'

export default function OfferLogistic() {

    const shortOfferRef = useRef()

    return (
        <div id='shortOffer' className={classes.short} ref={shortOfferRef}>
            <div>
                <img src='./img/icons/Logistic/key.svg' alt='boom' />
                <h2>Pronájem části nebo celého skladového prostoru podle potřeb klienta</h2>
            </div>
            <div>
                <img src='./img/icons/Logistic/case.svg' alt='boom' />
                <h2>Nabízíme služby správy skladu, včetně zajištění pracovní síly pro sklad, příjem, umístění, kompletaci zboží a organizaci prostoru pro optimalizaci logistických procesů</h2>
            </div>
            <div>
                <img src='./img/icons/Logistic/clock.svg' alt='boom' />
                <h2>Poskytování kontinuální podpory klientům při řešení jakýchkoli otázek nebo problémů spojených s personálem, a rovněž poskytování poradenství v oblasti optimalizace pracovních procesů</h2>
            </div>
            <div>
                <img src='./img/icons/Logistic/procces.svg' alt='boom' />
                <h2>Poskytování technické podpory pro zajištění spolehlivosti a efektivity skladových systémů, včetně servisu zařízení a softwarového vybavení</h2>
            </div>
            <div>
                <img src='./img/icons/Logistic/person.svg' alt='boom' />
                <h2>Poskytování individuálního přístupu ke každému klientovi, konzultace ohledně organizace skladových procesů, řešení aktuálních otázek a podpora při navazování dlouhodobých partnerství</h2>
            </div>
            <div>
                <img src='./img/icons/Logistic/lock.svg' alt='boom' />
                <h2>Poskytování trvalé podpory klientům při řešení jakýchkoli otázek nebo problémů souvisejících s personálem, a také poradenství v oblasti optimalizace pracovních procesů</h2>
            </div>
        </div>
    );
}